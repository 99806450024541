import {
  type ActorRefFrom,
  type AnyStateMachine,
  createMachine as xCreateMachine,
  assign as xAssign,
  spawn,
} from "xstate";
import type { EventBus, GenericEvent } from "@fscrypto/state-management";
import { eventBus } from "./events";
import { type ActorSystemKey, type System, actorSystem } from "./system";
import { EMPTY, Observable, Subject, filter } from "rxjs";

export interface Epic<E extends GenericEvent> {
  send: (e: E) => void;
}

type CreateParams<
  TMachine extends AnyStateMachine,
  Key extends string,
  TInitial extends object,
  InputEvent extends GenericEvent,
  OutputEvent extends GenericEvent,
> = {
  createMachine: (id: Key, p: TInitial) => TMachine;
  createEpic?: (a: Observable<InputEvent>) => Observable<OutputEvent>;
};

const createActorWithDeps =
  <E extends GenericEvent>(system: System, eventBus: EventBus<E>) =>
  <
    Key extends string,
    TMachine extends AnyStateMachine,
    InputEvent extends GenericEvent = GenericEvent,
    OutputEvent extends GenericEvent = GenericEvent,
    TActor = ActorRefFrom<TMachine>,
    TInitial extends object = {},
  >(
    { createMachine, createEpic }: CreateParams<TMachine, Key, TInitial, InputEvent, OutputEvent>,
    createConfig: ({
      system,
      eventBus,
      epic,
    }: {
      system: System;
      eventBus: EventBus<E>;
      epic: Epic<InputEvent>;
    }) => Parameters<TMachine["withConfig"]>["0"],
  ) =>
  (id: Key, p: TInitial, replace = false) => {
    const ref = system.get(id as ActorSystemKey);
    if (ref) {
      if (!replace) {
        return ref as TActor;
      }
      system.unregister(id as ActorSystemKey);
    }
    const epic$$ = new Subject<InputEvent>();
    const epic$ = createEpic ? createEpic(epic$$) : (EMPTY as Observable<OutputEvent>);
    const epic: Epic<InputEvent> = {
      send: (e) => epic$$.next(e),
    };
    const machine = createMachine(id, p);
    const config = createConfig({ system, eventBus, epic });
    if (!machine.config.initial) {
      throw new Error("Machine must specify an initial state");
    }
    const globalEventNames = collectGlobalEvents(machine.config);
    const filteredGlobalEvents$ = eventBus.events$.pipe(filter((event) => globalEventNames.includes(event.type)));
    const enhancedMachine = xCreateMachine(
      {
        ...machine.config,
        initial: "fsc/setup",
        states: {
          "fsc/setup": {
            entry: ["fsc/setupListeners"],
            always: machine.config.initial as string,
          },
          ...machine.config.states,
        },
      },
      {
        ...config,
        actions: {
          ...(config.actions ?? {}),
          "fsc/setupListeners": xAssign({
            epic: () => spawn(epic$),
            bus: () => spawn(filteredGlobalEvents$),
          }),
        },
      },
    ) as TMachine;
    const actor = system.registerMachine(enhancedMachine, id as ActorSystemKey);
    return actor as TActor;
  };

type StateNode = {
  on?: {
    [event: string]: any;
  };
  states?: {
    [state: string]: StateNode;
  };
  [key: string]: any;
};

// this traverses through the machine config and collects all global events
function collectGlobalEvents(stateNode: StateNode): string[] {
  let globalEvents: string[] = [];

  function traverse(node: StateNode | undefined) {
    if (!node) return;

    if (node.on) {
      for (const event of Object.keys(node.on)) {
        if (event.startsWith("GLOBAL.")) {
          globalEvents.push(event);
        }
      }
    }

    if (node.states) {
      for (const subState of Object.values(node.states)) {
        traverse(subState);
      }
    }
  }

  traverse(stateNode);
  return globalEvents;
}

export const createActor = createActorWithDeps(actorSystem, eventBus);
